import { useNavigate } from '@remix-run/react';
import { type ReactElement } from 'react';

export default function ErrorPage({
  status,
  statusTitle,
  statusText,
}: {
  status?: number;
  statusTitle?: string;
  statusText?: string | ReactElement;
}): ReactElement {
  const navigate = useNavigate();
  return (
    <>
      <div className="shadow-2xl min-h-full rounded-lg bg-slate-100 px-4 py-10 sm:px-6 sm:py-20 md:grid md:place-items-center lg:px-10">
        <div className="mx-auto max-w-max">
          <main className="sm:flex">
            <p className="text-4xl font-bold tracking-tight text-indigo-600 sm:text-5xl">{status || 404}</p>
            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-slate-200 sm:pl-6">
                <h1 className="text-4xl font-semibold tracking-tight text-slate-700 sm:text-5xl">
                  {statusTitle || 'Error'}
                </h1>
                <div className="mt-5 text-base text-slate-500">{statusText || 'Please try again later'}</div>
              </div>
              <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                <button
                  className="btn-primary"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Go back
                </button>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
